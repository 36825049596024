import React from 'react';
import { navigate } from 'gatsby';
import { Layout } from 'src/components/Layout';
import styles from './styles.module.css';
import { Spinner } from 'src/components/Spinner';
import { TextInput } from 'src/components/TextInput/component';
import { ArrowButton } from 'src/components/ArrowButton/component';
import { ExternalSkillManager } from 'src/helpers/ExternalSkillManager/ExternalSkillManager';
import { UserManager } from 'src/helpers/UserManager';
import { showMessage } from 'src/components/Message';

interface Props {
	heading: string;
}

interface State {
	loading: boolean;
	success: boolean;
	id: number;
	user: number;
	title: string;
	skillTested: string;
	info: string;
	grade: string;
	provider: string;
	existingID?: number;
	isXceptionalSkill: boolean;
	errors: boolean[];
}

export default class ExternalSkill extends React.Component<Props, State> {
	state: State = {
		loading: true,
		success: false,
		id: 0,
		user: 0,
		title: '',
		skillTested: '',
		info: '',
		grade: '',
		provider: '',
		isXceptionalSkill: false,
		errors: [false, false, false, false, false],
	};

	async componentDidMount() {
		let { errors } = this.state;
		const user = await UserManager.getAuthenticatedUser();
		if (!user || user.guest) {
			// no user found so send them to sign in
			navigate('/sign-in?url=add-skill');
			return;
		} else {
			let urlParams;
			let existingID: number | undefined | null = undefined;
			// Window is not accessible during server side deployment, so it must be checked prior to usage as shown here
			if (typeof window !== 'undefined') {
				urlParams = new URLSearchParams(window.location.search);
			}
			existingID = urlParams ? Number(urlParams.get('existing')) : undefined;
			const skills = await ExternalSkillManager.getAllExternalSkillsForUser(user.id || -1);
			let validID = false;
			skills.allExternalSkills.map((v) => {
				if (v.id == existingID) {
					validID = true;
					this.setState({
						title: v.testTitle,
						skillTested: v.proficiencyType,
						info: v.info,
						grade: v.grade,
						provider: v.provider,
						isXceptionalSkill: v.provider.toLowerCase() == 'xceptional',
					});
				}
			});
			if (!validID) {
				if (existingID) console.warn(`Existing id is not valid`);
				existingID = undefined;
				errors = [true, true, false, true, true];
			}
			this.setState({
				user: user.id || -1,
				loading: false,
				existingID,
				errors,
			});
		}
	}

	saveExternalSkill = async () => {
		const { user, title: testTitle, skillTested, info, grade, provider, existingID } = this.state;
		const hasValidResponses =
			testTitle !== '' &&
			skillTested !== '' &&
			grade !== '' &&
			provider !== '' &&
			skillTested !== '';
		if (hasValidResponses) {
			let result;
			if (existingID) {
				result = await ExternalSkillManager.updateExternalSkill({
					id: existingID,
					testTitle,
					proficiencyType: skillTested,
					info,
					grade,
					provider,
				});
			} else {
				result = await ExternalSkillManager.createExternalSkill({
					user,
					testTitle,
					proficiencyType: skillTested,
					info,
					grade,
					provider,
				});
			}
			if (result?.success && !result?.error) {
				this.setState({ success: true });
				navigate('/profile');
			} else {
				this.setState({ success: false });
				showMessage({ caption: 'Failed to save external skill' });
				console.error('An error occurred saving the skill. ' + result.error);
			}
		} else {
			this.setState({ success: false });
			showMessage({ caption: 'Unable to save changes as some required fields have invalid data' });
			scrollTo(0, 0);
		}
	};

	goToProfileScreen = () => {
		navigate('/profile');
	};

	render() {
		const { loading, existingID, errors } = this.state;

		return (
			<Layout>
				<div className={styles.container}>
					{!loading ? (
						<div>
							<div className={styles.header}>
								<div className={styles.bookend}>
									<ArrowButton
										isBackButton={true}
										caption={'Back'}
										onClick={this.goToProfileScreen}
									/>
								</div>
								{existingID ? <h1>Edit Skill</h1> : <h1>Add Skill</h1>}
								<div className={styles.bookend}></div>
							</div>

							<hr />

							<div className={styles.row}>
								<div className={styles.column}>
									<TextInput
										caption="Test Title:"
										isRequired
										value={this.state.title}
										onValueChanged={(value) => {
											errors[0] = !(value && value.length <= 120);
											this.setState({ title: value, errors });
										}}
										readOnly={this.state.isXceptionalSkill}
										validate={(value) => value && value.length <= 120}
										validationMsg={`This field is required and must be 120 characters or less`}
									/>
								</div>
								<div className={styles.column}>
									<TextInput
										caption={'Skill Tested:'}
										isRequired
										value={this.state.skillTested}
										onValueChanged={(value) => {
											errors[1] = !(value && value.length <= 250);
											this.setState({ skillTested: value, errors });
										}}
										readOnly={this.state.isXceptionalSkill}
										validate={(value) => value && value.length <= 250}
										validationMsg={`This field is required and must be 250 characters or less`}
									/>
								</div>
							</div>

							<div className={styles.row}>
								<div className={styles.column}>
									<TextInput
										caption="Information about this test:"
										isMultiLine
										value={this.state.info}
										onValueChanged={(value) => {
											errors[2] = !(value.length <= 6000);
											this.setState({ info: value, errors });
										}}
										readOnly={this.state.isXceptionalSkill}
										validate={(value) => value.length <= 6000}
										validationMsg={`This field must be 6000 characters or less`}
									/>
								</div>
							</div>

							<div className={styles.row}>
								<div className={styles.column}>
									<TextInput
										caption="My mark or grade:"
										isRequired
										value={this.state.grade}
										onValueChanged={(value) => {
											errors[3] = !(value && value.length <= 250);
											this.setState({ grade: value, errors });
										}}
										readOnly={this.state.isXceptionalSkill}
										validate={(value) => value && value.length <= 250}
										validationMsg={`This field is required and must be 250 characters or less`}
									/>
								</div>
								<div className={styles.column}>
									<TextInput
										caption="Provider:"
										isRequired
										value={this.state.provider}
										onValueChanged={(value) => {
											errors[4] = !(value && value.length <= 250);
											this.setState({ provider: value, errors });
										}}
										readOnly={this.state.isXceptionalSkill}
										validate={(value) => value && value.length <= 250}
										validationMsg={`This field is required and must be 250 characters or less`}
									/>
								</div>
							</div>

							<div className={styles.footer}>
								<hr className={styles.hr} />
								<ArrowButton
									isBackButton={false}
									caption={existingID ? 'Save' : 'Add'}
									onClick={this.saveExternalSkill}
									isDisabled={this.state.isXceptionalSkill || errors.includes(true)}
								/>
							</div>
						</div>
					) : (
						<Spinner />
					)}
				</div>
			</Layout>
		);
	}
}
